import React from "react";
import { AppWrapper } from "./src/state/appState";

export const wrapRootElement = ({ element }) => {
    return (
        <>
            <AppWrapper>{element}</AppWrapper>
        </>
    );
};

const transitionDelay = 300;

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => {
    console.log("path is " + location.pathname);
    if (location.action === "PUSH") {
        window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
    } else if (location.action !== "POP") {
        const savedPosition = getSavedScrollPosition(location);
        window.setTimeout(
            () => window.scrollTo(...(savedPosition || [0, 0])),
            transitionDelay,
        );
    } else {
        window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
    }
    return false;
};
