import { graphql, useStaticQuery } from "gatsby";

import { QueryFragments } from "./queryFragments"; // eslint-disable-line

export const useSiteConfig = () => {
    const { sanitySiteConfig } = useStaticQuery(graphql`
        query {
            sanitySiteConfig {
                instagram
                twitter
                facebook
                phone
                aucklandAddress: _rawAucklandAddress(
                    resolveReferences: { maxDepth: 10 }
                )
                christchurchAddress: _rawChristchurchAddress(
                    resolveReferences: { maxDepth: 10 }
                )
                mainNav {
                    ... on SanityPageLink {
                        _type
                        ...PageLinkFields
                    }
                    ... on SanityPathLink {
                        _type
                        ...PathLinkFields
                    }
                }
                footerNav {
                    ... on SanityPageLink {
                        _type
                        ...PageLinkFields
                    }
                    ... on SanityPathLink {
                        _type
                        ...PathLinkFields
                    }
                }
            }
        }
    `);
    return sanitySiteConfig || {};
};
