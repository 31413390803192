export const usePadBottom = (size) => {
    switch (size) {
        case "small":
            return "pb-[32px] sm:pb-[50px] lg:pb-[80px]";
        case "medium":
            return "pb-[50px] sm:pb-[80px] lg:pb-[100px]";
        case "large":
            return "pb-[60px] sm:pb-[100px] lg:pb-[140px]";
        default:
            return "";
    }
};
