import React, { useState, useEffect, useCallback } from "react";
import { useEmblaCarousel } from "embla-carousel/react";
import { setupWheelGestures } from "embla-carousel-wheel-gestures";

import NavLeft from "../../svg/nav-right.svg";
import NavRight from "../../svg/nav-left.svg";

const CenteredCarousel = ({
    children,
    className,
    centered,
    setActiveSlide,
}) => {
    const [scrollProgress, setScrollProgress] = useState(0);

    const [emblaRef, emblaApi] = useEmblaCarousel({
        align: centered === true ? "center" : "start",
        loop: "true",
        startIndex: centered === true ? 1 : 0,
    });

    const onSelect = useCallback(() => {
        if (!emblaApi) return;
        setActiveSlide(emblaApi.selectedScrollSnap());
    }, [emblaApi]);

    useEffect(() => {
        if (emblaApi) {
            // Embla API is ready

            onSelect();
            emblaApi.on("select", onSelect);
        }
    }, [emblaApi, onSelect]);

    return (
        <div className={`relative z-10 ${className}`}>
            <button
                onClick={() => {
                    emblaApi.scrollPrev();
                    setActiveSlide(emblaApi.selectedScrollSnap());
                }}
                className="overflow-x-hidden outline-none absolute z-20 left-0 lg:left-10 top-0 bottom-0 w-1/6 lg:w-1/5 py-6 flex items-center justify-start"
            >
                <NavLeft className="w-3 md:w-6 h-auto text-white fill-current translate-x-2" />
            </button>
            <button
                onClick={() => {
                    emblaApi.scrollNext();
                    setActiveSlide(emblaApi.selectedScrollSnap());
                }}
                className="overflow-x-hidden  outline-none absolute z-20 right-0 lg:right-10 top-0 bottom-0 w-1/6 lg:w-1/5 py-6  flex items-center justify-end"
            >
                <NavRight className="w-3 md:w-6 h-auto text-white fill-current -translate-x-2" />
            </button>
            <div
                // style={{ overflowX: "visible !important" }}
                className={`${centered === true && "overflow-hidden w-full"} ${
                    centered !== true && "w-full"
                }`}
                ref={emblaRef}
            >
                <div className="flex -ml-3 md:-ml-14 ">{children}</div>
            </div>
        </div>
    );
};

export default CenteredCarousel;
