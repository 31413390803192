import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useSiteConfig } from "../../GraphQl/useSiteConfig";
import { useAppState } from "../../state/appState";
import { useScrollFreeze } from "../../hooks";
import { NavLink } from "./navLink";
import { FeaturedLinksMobileNav } from "../ui/featuredLinks";
import { Link } from "gatsby";

// Animation Variants
const navWrapper = {
    closed: {
        opacity: 0,
        transition: {
            duration: 0.5,
            delay: 0.4,
            ease: [0.43, 0.13, 0.23, 0.96],
        },
    },
    open: {
        opacity: 1,
        transition: {
            duration: 0.7,
            ease: [0.43, 0.13, 0.23, 0.96],
        },
    },
};

const NavInner = ({ toggleNav, serviceItems }) => {
    const { mainNav } = useSiteConfig();
    useScrollFreeze();

    const [serviceOpen, setServiceOpen] = useState(false);

    const toggleService = () => {
        setServiceOpen(!serviceOpen);
    };

    return (
        <motion.div
            className="pl-gutter h-screen w-full bg-warm-white flex items-start justify-start  flex-col z-2000 top-0 left-0 bottom-0 text-teal"
            variants={navWrapper}
            initial="closed"
            animate="open"
            exit="closed"
        >
            <nav
                className={`flex flex-col font-medium items-start uppercase blockH4 pt-5`}
            >
                <div className="my-4">
                    <button onClick={toggleService} className="uppercase">
                        services
                    </button>
                    <AnimatePresence>
                        {serviceOpen && (
                            <motion.div
                                initial={{ opacity: 0, height: 0 }}
                                animate={{ opacity: 1, height: "auto" }}
                                exit={{ opacity: 0, height: 0 }}
                                className="ml-4 overflow-hidden flex flex-col space-y-4 blockH6 "
                                transition={{
                                    duration: 0.5,
                                }}
                            >
                                <div className=" w-full h-[1px] pb-0"></div>
                                {serviceItems?.map((node, i) => (
                                    <motion.div>
                                        <Link
                                            onClick={toggleNav}
                                            to={`/services/${node?.slug?.current}`}
                                        >
                                            {node?.title}
                                        </Link>
                                    </motion.div>
                                ))}
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
                {mainNav.map((item, index) => (
                    <>
                        <NavLink
                            className="transition duration-300 my-4"
                            onClick={toggleNav}
                            {...item}
                        />
                    </>
                ))}
                <button className="my-4" onClick={toggleNav}>
                    <Link to="/contact" className="uppercase">
                        get in contact
                    </Link>
                </button>
            </nav>
            {/* <div className="absolute bottom-0 left-0 right-0">
        <FeaturedLinksMobileNav toggleNav={toggleNav} />
      </div> */}
        </motion.div>
    );
};

export const MobileNav = ({ serviceItems }) => {
    const { navOpen, toggleNav } = useAppState();
    return (
        <AnimatePresence>
            {navOpen && (
                <NavInner toggleNav={toggleNav} serviceItems={serviceItems} />
            )}
        </AnimatePresence>
    );
};
