export const usePadTop = (size) => {
    switch (size) {
        case "small":
            return "pt-[32px] sm:pt-[50px] lg:pt-[80px]";
        case "medium":
            return "pt-[50px] sm:pt-[80px] lg:pt-[100px]";
        case "large":
            return "pt-[60px] sm:pt-[100px] lg:pt-[140px]";
        default:
            return "";
    }
};
