import React, { createContext, useContext } from "react";

// Hooks
import { useToggle } from "../hooks";

export const AppState = createContext({
    navOpen: false,
    navDark: false,
    headerPinned: false,
});

export const AppWrapper = ({ children }) => {
    const [navOpen, setNavOpen, toggleNav] = useToggle(false);
    const [navDark, setNavDark, toggleNavDark] = useToggle(false);

    return (
        <AppState.Provider
            value={{
                navOpen,
                setNavOpen,
                toggleNav,
                navDark,
                toggleNavDark,
                setNavDark,
            }}
        >
            {children}
        </AppState.Provider>
    );
};

export const useAppState = () => useContext(AppState);
