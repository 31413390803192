import React from "react";
import { Link } from "gatsby";

export const FeaturedLinksNav = ({ setServiceOpen }) => {
    return (
        <div
            className="flex button text-center"
            onClick={() => setServiceOpen(false)}
        >
            <Link
                className="px-8 py-5 col-span-1 bg-teal text-warm-white hover:bg-raspberry hover:text-warm-white transition-all duration-300 ease-in-out"
                to="/contact"
            >
                Get in contact
            </Link>
        </div>
    );
};

export const FeaturedLinksBrand = () => {
    return (
        <div className="grid grid-cols-2 flex uppercase text-sm xl:text-base text-center font-medium tracking-widest leading-tight">
            <Link
                className="button px-8 py-5 col-span-1 bg-teal text-warm-white hover:bg-raspberry"
                to="/contact"
            >
                Get in contact
            </Link>
            <Link
                className="button px-8 py-5 col-span-1 bg-teal text-warm-white hover:bg-raspberry"
                to="/work"
            >
                View our work
            </Link>
        </div>
    );
};

export const FeaturedLinksDark = () => {
    return (
        <div className="grid grid-cols-2 uppercase text-sm xl:text-base text-center font-medium tracking-widest leading-tight">
            <Link
                className="button px-8 py-5 col-span-1 bg-warm-white text-teal hover:bg-raspberry"
                to="/contact"
            >
                Get in contact
            </Link>
            <Link
                className="button px-8 py-5 col-span-1 bg-warm-white text-teal hover:bg-raspberry"
                to="/work"
            >
                View our work
            </Link>
        </div>
    );
};

export const FeaturedLinksMobileNav = ({ toggleNav }) => {
    return (
        <div className="grid grid-cols-2 uppercase text-sm xl:text-base text-center font-medium tracking-widest leading-tight">
            <Link
                onClick={toggleNav}
                className="px-4 py-2 col-span-1 bg-white text-brand"
                to="/contact"
            >
                Get in
                <br />
                Contact
            </Link>
            <Link
                onClick={toggleNav}
                className="px-4 py-2 col-span-1 bg-brand text-white"
                to="/work"
            >
                View Our
                <br />
                Work
            </Link>
        </div>
    );
};
