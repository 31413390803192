import React, { useState } from "react";

import { useSiteConfig } from "../../GraphQl/useSiteConfig";
import { useServiceQuery } from "../../GraphQl/useServiceQuery";
import cx from "classnames";
import { NavLink } from "./navLink";
import { PortableTextBlock } from "../sanity/portableTextBlock";
import { Link } from "gatsby";

import FooterArrow from "../../svg/yellow-arrow.svg";
import LightLogo from "../../svg/light-zoosh-logo.svg";
import YouTube from "../../svg/youtube.svg";
import Facebook from "../../svg/facebook.svg";
import LinkedIn from "../../svg/linkedin.svg";

export const Footer = () => {
    const {
        footerNav,
        aucklandAddress,
        christchurchAddress,
        facebook,
        twitter,
        instagram,
    } = useSiteConfig();

    const [footerHover, setFooterHover] = useState(null);
    const { nodes } = useServiceQuery();

    return (
        <div className="w-full text-warm-white bg-teal overflow-hidden ">
            <div className="grid grid-cols-1  md:grid-cols-2 sm:px-9 px-5 lg:px-20 py-20 pt-14 ">
                <div className="blockH6 font-bold text-warm-white grid grid-cols-2 gap-y-11  -translate-y-[2px] ">
                    <div className="col-start-1 flex flex-col">
                        <div className="md:hidden lg:block mb-auto">
                            <LightLogo className=" w-[114px] sm:w-[170px] lg:w-[209px] h-auto transition-all duration-200 text-white" />
                        </div>
                        <div className="">
                            <h3 className="blockH5 pb-6">Industries</h3>
                            <div className="blockH8 flex flex-col space-y-[12px] ">
                                {nodes?.map((node, i) => (
                                    <div>
                                        <Link
                                            to={`/services/${node?.slug?.current}`}
                                            className="hover:text-raspberry transition-all duration-000  ease-in-out"
                                        >
                                            {node?.title}
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="blockH5 col-start-2 flex flex-col space-y-[44px] ">
                        {footerNav.map((navItem, i) => (
                            <div
                                className="flex items-start space-x-4 w-full h-fit hover:text-raspberry transition-all duration-000  ease-in-out"
                                onMouseEnter={() => setFooterHover(i)}
                                onMouseLeave={() => setFooterHover(null)}
                            >
                                <NavLink
                                    className="pr-10 -mr-10"
                                    {...navItem}
                                />
                                <FooterArrow
                                    className={cx(
                                        "pointer-events-none self-center t",
                                        {
                                            "svg-raspberry": footerHover === i,
                                        },
                                    )}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:pl-20 pt-[59px] md:pt-0">
                    <div className="flex flex-col">
                        <div className="mb-auto">
                            <h3 className="blockH7 text-warm-white font-bold pb-7 ">
                                Auckland
                            </h3>
                            <PortableTextBlock text={aucklandAddress} />
                        </div>
                        <div className="hidden md:block">
                            <div className="flex flex-row gap-3">
                                <a
                                    href={`https://www.linkedin.com/company/zhoosh/`}
                                    target="_blank"
                                >
                                    <LinkedIn className="hover:svg-raspberry" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h3 className="blockH7 text-warm-white font-bold pb-7 pt-10 md:pt-0">
                            Christchurch
                        </h3>
                        <PortableTextBlock text={christchurchAddress} />
                    </div>
                    <div className="block md:hidden">
                        <div className="pt-10">
                            <a
                                href={`https://www.linkedin.com/company/zhoosh/`}
                                target="_blank"
                            >
                                <LinkedIn className="hover:svg-raspberry" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
