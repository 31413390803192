import React from "react";
import PortableText from "react-portable-text";

// Block Components
import {
    ScrollAnchorLink,
    ExternalLink,
    PageAnchorLink,
    PageLink,
    PathLink,
} from "../ui/links";
import { ImageModule, VideoModule } from "../modules";

const serializers = {
    h1: (props) => <h2 className="text-current blockH1" {...props} />, // eslint-disable-line
    h2: (props) => <h2 className="text-current  blockH2 " {...props} />, // eslint-disable-line
    h3: (props) => <h3 className=" blockH3 pb-3 " {...props} />, // eslint-disable-line
    h4: (props) => <h4 className="text-current blockH4" {...props} />, // eslint-disable-line
    h5: (props) => <h5 className="text-current blockH5" {...props} />, // eslint-disable-line
    h6: (props) => <h6 className="text-current blockH6" {...props} />, // eslint-disable-line
    "super-script": (props) => <sup>{props.children}</sup>,
    "sub-script": (props) => <sub>{props.children}</sub>,
    pageLink: (props) => <PageLink {...props} className="underline" />,
    pathLink: (props) => <PathLink {...props} className="underline" />,
    anchorLink: (props) => (
        <ScrollAnchorLink
            to={props.slug && props.slug.current}
            linkText={props.linkText}
            className="underline"
        />
    ),
    ul: (props) => (
        <ul
            className="list-disc pl-5 marker:text-black blockH9 pb-5 space-y-2   "
            {...props}
        />
    ), // eslint-disable-line
    ol: (props) => (
        <ol
            className="list-decimal pl-5 marker:text-black blockH9 pb-5 space-y-2   "
            {...props}
        />
    ), // eslint-disable-line
    li: (props) => <li className=" " {...props} />, // eslint-disable-line
    externalLink: (props) => <ExternalLink {...props} className="underline" />,
    pageAnchorLink: (props) => (
        <PageAnchorLink {...props} className="underline" />
    ),
    normal: (props) => <p {...props} className="blockH9" />,
    highlight: (props) => <span {...props} className="" />,
    imageModule: (props) => <ImageModule {...props} />,
    videoModule: (props) => <VideoModule {...props} />,
    link: (props) => <a {...props} className="" />,
    underline: (props) => (
        <u
            {...props}
            className="hover:text-raspberry transition-all duration-300 ease-in-out"
        />
    ),
}; // eslint-disable-line

export const PortableTextBlock = ({ text, ...props }) => {
    return <PortableText content={text} serializers={serializers} {...props} />;
};
