import React, { useState, useEffect, useCallback } from "react";
import { useEmblaCarousel } from "embla-carousel/react";
import { setupWheelGestures } from "embla-carousel-wheel-gestures";
import { useGesture } from "react-use-gesture";
import {
    motion,
    useMotionValue,
    useSpring,
    AnimatePresence,
} from "framer-motion";

import NavLeft from "../../svg/nav-right.svg";
import NavRight from "../../svg/nav-left.svg";

const cursorVars = {
    visible: {
        scale: 1,
        opacity: 1,
    },
    dragging: {
        scale: 0.5,
        opacity: 1,
    },
    hidden: {
        scale: 0.2,
        opacity: 0,
    },
};

const cursorTextVars = {
    visible: {
        opacity: 1,
    },
    dragging: {
        opacity: 0,
    },
    hidden: {
        opacity: 0,
    },
};

const StandardCarousel = ({ children, className }) => {
    const [scrollProgress, setScrollProgress] = useState(0);

    const [viewportRef, embla] = useEmblaCarousel({
        loop: false,
        align: "start",
        containScroll: "trimSnaps",
        dragFree: true,
        draggable: true,
    });

    const [cusorActive, setCursorActive] = useState(false);
    const [isDrag, setDrag] = useState(false);

    useEffect(() => {
        if (embla) {
            // Embla API is ready
            setTimeout(() => {
                embla.reInit();
            }, 2000);
        }
    }, [embla]);

    const cursorX = useMotionValue(-100);
    const cursorY = useMotionValue(-100);

    const springConfig = { damping: 40, stiffness: 300 };
    const cursorXSpring = useSpring(cursorX, springConfig);
    const cursorYSpring = useSpring(cursorY, springConfig);

    const bind = useGesture({
        onHover: ({ hovering }) => setCursorActive(hovering),
        onDrag: ({ dragging }) => setDrag(dragging),
        onMove: ({ values }) => {
            cursorX.set(values[0] - 40);
            cursorY.set(values[1] - 40);
        },
    });

    // const onScroll = useCallback(() => {
    //   if (!emblaApi) return;
    //   setScrollProgress(emblaApi.scrollProgress() * 100);
    // }, [emblaApi]);

    // useEffect(() => {
    //   if (emblaApi) {
    //     // Embla API is ready
    //     emblaApi.on('scroll', onScroll);
    //     setupWheelGestures(emblaApi);
    //   }
    // }, [emblaApi, onScroll]);

    return (
        <>
            <AnimatePresence>
                {cusorActive && (
                    <motion.div
                        style={{
                            width: "112px",
                            height: "112px",
                            translateX: cursorXSpring,
                            translateY: cursorYSpring,
                        }}
                        className=" fixed left-0 top-0 rounded-full z-50 pointer-events-none cursor-none hidden md:flex items-center justify-center drag-cursor bg-raspberry text-warm-white"
                        variants={cursorVars}
                        initial="hidden"
                        animate={isDrag ? "dragging" : "visible"}
                        exit="hidden"
                    >
                        <motion.span
                            className="blockH8"
                            variants={cursorTextVars}
                        >
                            Drag
                        </motion.span>
                    </motion.div>
                )}
            </AnimatePresence>
            <div className={`px-gutter relative ${className}`} revealAnimation>
                {/* <button
          onClick={() => embla.scrollPrev()}
          className="outline-remove absolute z-20 left-0 top-0 bottom-0 w-1/6 lg:w-1/5 p-6 flex items-center justify-start"
        >
          <NavLeft className="w-6 h-auto text-white fill-current" />
        </button>
        <button
          onClick={() => embla.scrollNext()}
          className="outline-remove absolute z-20 right-0 top-0 bottom-0 w-1/6 lg:w-1/5 p-6 flex items-center justify-end"
        >
          <NavRight className="w-6 h-auto text-white fill-current" />
        </button> */}
                <div
                    style={{ overflowX: "visible !important" }}
                    className={`w-full`}
                    ref={viewportRef}
                    {...bind()}
                >
                    <div className="flex space-x-[25px]">{children}</div>
                </div>
            </div>
        </>
    );
};

export default StandardCarousel;
