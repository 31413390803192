import React, { useState } from "react";

import { useSiteConfig } from "../../GraphQl/useSiteConfig";
import { useServiceQuery } from "../../GraphQl/useServiceQuery";
import { AnimatePresence, motion } from "framer-motion";
import { useAppState } from "../../state/appState";
import { Link } from "gatsby";

import PrimaryLogo from "../../svg/zoosh-primary-logo.svg";
import { NavLink } from "./navLink";
import { FeaturedLinksNav } from "../ui/featuredLinks";
import { MobileNav } from "./MobileNav";
import { MenuToggle } from "./menuToggle";
import cx from "classnames";

const container = {
    hidden: { opacity: 0 },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.2,
        },
    },
};

const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
};

export const Header = () => {
    const { navOpen, toggleNav } = useAppState();

    const { mainNav } = useSiteConfig();
    const { nodes } = useServiceQuery();

    const [serviceOpen, setServiceOpen] = useState(false);

    const toggleService = () => {
        setServiceOpen(!serviceOpen);
    };

    return (
        <>
            <header className="bg-warm-white flex justify-between items-center z-2500 top-0 w-full px-4 md:px-[43px] py-[36px] sm:py-[34px] lg:py-[30px] ">
                <Link to="/" onClick={() => setServiceOpen(false)}>
                    <PrimaryLogo className="w-[114px] sm:w-[170px] lg:w-[209px] h-auto transition-all duration-200" />
                </Link>
                <MenuToggle
                    navOpen={navOpen}
                    className="lg:hidden"
                    onClick={toggleNav}
                />
                <div className="hidden lg:flex blockH11 font-bold">
                    <nav className="flex justify-center items-center">
                        <div className="z-2500 mr-10 relative">
                            <button
                                onClick={toggleService}
                                className={cx(
                                    "button tracking-widest cursor-pointer hover:text-raspberry transition-all duration-500  ease-in-out",
                                    {},
                                )}
                            >
                                industries
                            </button>

                            <motion.span
                                initial={{ width: 0, opacity: 0 }}
                                animate={{
                                    width: serviceOpen ? "100%" : 0,
                                    opacity: serviceOpen ? 1 : 0,
                                }}
                                exit={{ width: 0, opacity: 0 }}
                                className="w-full h-[2px] inline-block absolute top-5 left-0  bg-raspberry"
                            />

                            <motion.div
                                variants={container}
                                initial="hidden"
                                animate={serviceOpen ? "show" : "hidden"}
                                className="flex flex-col space-y-[18px]   absolute top-[36px] left-0 w-[200px]"
                            >
                                {nodes?.map((node, i) => (
                                    <motion.div variants={item}>
                                        <Link
                                            to={
                                                serviceOpen
                                                    ? `/services/${node?.slug?.current}`
                                                    : null
                                            }
                                            onClick={() =>
                                                setServiceOpen(false)
                                            }
                                            className={cx(
                                                "hover:text-raspberry transition-all duration-500  ease-in-out",
                                                {
                                                    "cursor-pointer":
                                                        serviceOpen,
                                                    "cursor-default":
                                                        !serviceOpen,
                                                },
                                            )}
                                        >
                                            {node?.title}
                                        </Link>
                                    </motion.div>
                                ))}
                            </motion.div>
                        </div>
                        {mainNav.map((item) => (
                            <NavLink
                                className="mr-10 button tracking-widest hover:text-raspberry transition-all duration-300  ease-in-out"
                                {...item}
                                onClick={() => setServiceOpen(false)}
                            />
                        ))}
                    </nav>
                    <FeaturedLinksNav setServiceOpen={setServiceOpen} />
                </div>
            </header>
            <MobileNav {...mainNav} serviceItems={nodes} />
        </>
    );
};
