import { graphql, useStaticQuery } from "gatsby";

import { QueryFragments } from "./queryFragments"; // eslint-disable-line

export const useServiceQuery = () => {
    const { allSanityService } = useStaticQuery(graphql`
        query {
            allSanityService(sort: { fields: order }) {
                nodes {
                    title
                    slug {
                        current
                    }
                }
            }
        }
    `);
    return allSanityService || {};
};
