import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "@popmotion/popcorn";

import NavLeft from "../../svg/nav-right.svg";
import NavRight from "../../svg/nav-left.svg";

const MotionCarousel = ({ children }) => {
    const [slideIndex, setSlideIndex] = useState(0);
    const wrappedIndex = wrap(0, children.length, slideIndex);
    const Length = children.length - 1;

    return (
        <div className="relative">
            <AnimatePresence exitBeforeEnter>
                <div
                    {...children[wrappedIndex]}
                    key={slideIndex}
                    drag="x"
                    dragMomentum={false}
                    dragElastic={false}
                    dragConstraints={{ left: 0, right: 0 }}
                    onDragEnd={(e, { offset, velocity }) => {
                        if (offset.x > 100) {
                            setSlideIndex(slideIndex - 1);
                        } else if (offset.x < -100) {
                            setSlideIndex(slideIndex + 1);
                        }
                    }}
                    transition={{ duration: 0.3 }}
                >
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        {children[wrappedIndex]}
                    </motion.div>
                </div>
            </AnimatePresence>
            {children.length > 1 && (
                <nav className="flex space-x-16 lg:space-x-0 lg:justify-between px-gutter pt-8">
                    <motion.a
                        href="#testTop"
                        className={`outline-remove ${
                            slideIndex === 0 && "opacity-50 pointer-events-none"
                        } 2xl:absolute z-20 left-6 lg:top-1/2 lg:transform lg:-translate-y-2/4`}
                        onClick={() => setSlideIndex(slideIndex - 1)}
                    >
                        <NavLeft className="w-4 lg:w-6 h-auto cursor-pointer text-white fill-current" />
                    </motion.a>
                    <motion.a
                        href="#testTop"
                        className={`2xl:absolute z-20 right-6 lg:top-1/2 lg:transform lg:-translate-y-2/4  outline-remove ${
                            slideIndex === Length &&
                            "opacity-50 pointer-events-none"
                        }`}
                        onClick={() => setSlideIndex(slideIndex + 1)}
                    >
                        <NavRight className="w-4 lg:w-6 h-auto cursor-pointer text-white fill-current" />
                    </motion.a>
                </nav>
            )}
        </div>
    );
};

export default MotionCarousel;
