import React from "react";

import { AnimatePresence, motion } from "framer-motion";

import "../css/main.css";

import { useLocation } from "@reach/router";

import { Header } from "../components/nav/header";
import { Footer } from "../components/nav/footer";

const Layout = ({ children }) => {
    const { pathname } = useLocation();

    return (
        <>
            <Header />
            <main
                className="w-full top-0 left-0"
                key={pathname}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                    ease: "easeInOut",
                    duration: 0.3,
                }}
            >
                {children}
                <Footer />
            </main>
        </>
    );
};

export default Layout;
