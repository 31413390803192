import React, { useState, useEffect, useCallback } from "react";
import { useEmblaCarousel } from "embla-carousel/react";
import { setupWheelGestures } from "embla-carousel-wheel-gestures";

import NavLeft from "../../svg/nav-right.svg";
import NavRight from "../../svg/nav-left.svg";

const RowCarousel = ({ children, className }) => {
    const [scrollProgress, setScrollProgress] = useState(0);

    const [emblaRef, emblaApi] = useEmblaCarousel({
        loop: false,
        dragFree: true,
        align: "start",
        containScroll: true,
    });

    // const onScroll = useCallback(() => {
    //   if (!emblaApi) return;
    //   setScrollProgress(emblaApi.scrollProgress() * 100);
    // }, [emblaApi]);

    // useEffect(() => {
    //   if (emblaApi) {
    //     // Embla API is ready
    //     emblaApi.on('scroll', onScroll);
    //     setupWheelGestures(emblaApi);
    //   }
    // }, [emblaApi, onScroll]);

    return (
        <div className={`relative ${className}`}>
            <div
                style={{ overflowX: "visible !important" }}
                className={`w-full mb-6 lg:mb-0`}
                ref={emblaRef}
            >
                <div className="flex">{children}</div>
            </div>
            <button
                onClick={() => emblaApi.scrollPrev()}
                className="outline-remove lg:absolute w-1/2 lg:w-auto z-20 left-0 top-0 bottom-0 pl-gutter lg:p-6 inline-block lg:flex items-center justify-start transform lg:-translate-x-full text-left"
            >
                <NavLeft className="inline-block w-6 h-auto" />
            </button>
            <button
                onClick={() => emblaApi.scrollNext()}
                className="outline-remove lg:absolute w-1/2 lg:w-auto z-20 right-0 top-0 bottom-0 pr-gutter lg:p-6 inline-block lg:flex items-center justify-end text-right"
            >
                <NavRight className="inline-block fill-current w-6 h-auto" />
            </button>
        </div>
    );
};

export default RowCarousel;
